.swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
  /* box-shadow: 1px 2px 6px 4px rgba(53, 57, 68, 0.2); */
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #000;
  text-align: center;
  transition: all 0.3s;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: transparent;
  color: transparent;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 13px;
  content: '';
  width: 20px;
  height: 20px;
}

.swiper-button-next::after {
  background: url('/icons/chevron-right.svg');
}
.swiper-button-prev::after {
  background: url('/icons/chevron-left.svg');
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1;
}
